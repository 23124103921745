<template>
  <!-- v-shortkey="{closing: ['esc'], opening: ['a']}" @shortkey.native="actionsKey" -->
  <v-navigation-drawer
    v-model="isShow"
    class="sidedar-content"
    :temporary="temporary"
    :mobile-breakpoint="'mobile-break-point'"
    :clipped="clipped"
    :class="setClassMenu"
    :width="widthSidebar"
    :stateless="stateless"
    :permanent="permanent"
    :fixed="fixed"
    :floating="floating"
    :disable-resize-watcher="'disable-resize-watcher'"
    :style="hideScrool"
    touchless
    right
    app
    @input="inputEvent">
    <v-app-bar
      v-if="toolbar"
      class="elevation-1"
      color="primary"
      height="50px"
      clipped-left
      dark>
      <v-btn
        class="btn-close ml-n2 mr-4"
        data-cy="close"
        dark
        icon
        small
        @click.native="showCloseModal = true">
        <v-icon>{{ $root.icons.action.close }}</v-icon>
      </v-btn>
      <h2 data-cy="sidebar-content-title">
        {{ title }}
      </h2>
    </v-app-bar>
    <v-container
      grid-list-xs
      class="my-0 pb-0 sidebar-context">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation>
        <v-layout
          v-resize="onResize"
          row
          wrap
          :style="checkAutoHeight">
          <slot />
        </v-layout>
        <div class="text-right">
          <v-btn
            v-if="!hideSubmit"
            color="accent"
            class="mr-3"
            data-cy="submit"
            :disabled="!valid"
            :loading="isLoading"
            @click="submit">
            {{ submitLabel }}
          </v-btn>
        </div>
      </v-form>
    </v-container>

    <modal
      max-width="450px"
      btn-primary-label="Confirmar"
      :show="showCloseModal"
      @close="showCloseModal = false"
      @submit="closing">
      <span slot="title">Tem certeza que deseja fechar?</span>
      <p
        slot="content"
        class="subtitle-1"
        :class="{ 'mt-4 mb-0': isMobile }">
        Todos os seus dados serão perdidos.
      </p>
    </modal>
  </v-navigation-drawer>
</template>

<script>
  import resizing from '@/mixins/resizing'
  import validate from '@/mixins/validate'
  import Modal from '@/components/ui/Modal'
  import { mapGetters } from 'vuex'

  export default {
    components : { Modal },
    mixins: [ resizing, validate ],
    props: {
      open: {
        type: Boolean,
        require: true,
        default: false
      },
      title: {
        type: String,
        default: '',
        require: true
      },
      submitLabel: {
        type: String,
        default: 'Salvar'
      },
      hideSubmit: {
        type: Boolean,
        default: false
      },
      temporary: {
        type: Boolean,
        default: true
      },
      clipped: {
        type: Boolean,
        default: false
      },
      width: {
        type: [ Number, String ],
        default: ''
      },
      hasMenu: {
        type: Boolean,
        default: false
      },
      toolbar: {
        type: Boolean,
        default: true
      },
      stateless: {
        type: Boolean,
        default: false
      },
      'hideOverlay': {
        type: Boolean,
        default: false
      },
      fixed: {
        type: Boolean,
        default: true
      },
      floating: {
        type: Boolean,
        default: true
      },
      permanent: {
        type: Boolean,
        default: true
      },
      'mobileBreakPoint': {
        type: [Number, String],
        default: 1264
      },
      'disableResizeWatcher': {
        type: Boolean,
        default: false
      },
      'miniVariantWidth': {
        type: [Number, String],
        default: 80
      },
      titleContent: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        valid: true,
        isDisabled: false,
        isShow: this.open,
        showCloseModal: false
      }
    },
    computed: {
      ...mapGetters({
        isLoading: 'app/getLoading'
      }),
      isMobile () {
        return this.$vuetify.breakpoint.xsOnly
      },
      widthSidebar () {
        const innerWidth = window.innerWidth

        if (this.width !== '') {
          return parseInt(this.width) + this.miniVariantWidth
        } else {
          return this.isMobile ? innerWidth : (innerWidth * 60) / 100
        }
      },
      setClassMenu () {
        return this.hasMenu ? 'has-menu' : ''
      },
      checkAutoHeight () {
        return this.hasMenu ? '' : this.setHeightScroll
      },
      hideScrool () {
        // return this.hasMenu ? '' : 'overflow: hidden;'
        return ''
      },
      hasTitle () {
        return this.titleContent !== ''
      }
    },
    watch: {
      open (val) {
        this.isShow = val
        this.$nextTick(() => {
          val ? this.$emit('opened') : this.$emit('close')
        })
      }
    },
    mounted () {
      this.isShow = this.open
    },
    methods: {
      closing () {
        this.isShow = false
        this.$emit('close')
      },
      opening () {
        this.isShow = true
      },
      submit () {
        if (this.$refs.form.validate()) {
          this.$emit('submit')
        }
      },
      inputEvent (status) {
        this.$emit('input', status)
        !status && this.$emit('close')
      },
      actionsKey (event) {
        switch (event.srcKey) {
        case 'closing':
          this.closing()
          console.log('closing')
          break
        case 'opening':
          this.opening()
          console.log('opening')
          break
        }
      }
    }
  }
</script>

<style lang="sass">
  .v-navigation-drawer
    &.sidedar-content
      overflow: hidden
      z-index: 11

    .area-scroll
      overflow-y: auto
      height: 100%

  .btn-close
    background: rgba(255,255,255, .1)

  .sidebar-context
    &.container.grid-list-xs
      padding: 2px
</style>
