<template>
  <v-dialog
    content-class="sideModal ma-0 d-flex"
    :value="true"
    persistent
    no-click-animation
    width="880"
    @click:outside="close">
    <v-card id="ContextualMenu">
      <v-navigation-drawer
        id="menu-contextual"
        v-model="openMenu"
        persistent
        mini-variant
        enable-resize-watcher
        absolute
        dark
        mini-variant-width="80"
        class="primary contextual-menu"
        stateless
        hide-overlay
        mobile-breakpoint="600">
        <v-list color="primary" class="menu-list contextual-menu-list py-0">
          <v-list-item
            v-for="(menu, index) in itemsMenu"
            :key="menu.label"
            ref="menu"
            :aria-selected="menu.active"
            :to="{ name: menu.to.name }"
            role="menu-item"
            :aria-label="menu.label"
            @click="changeMenu(menu)">
            <v-tooltip
              :ref="index"
              right>
              <template v-slot:activator="{ on }">
                <font-awesome-icon
                  v-if="menu.label === 'Propostas'"
                  :icon="menu.icon" />
                <img
                  v-else-if="menu.label === 'Integração Pagar.me'"
                  width="24px"
                  height="24px"
                  src="@/assets/images/pagarme.svg">
                <v-icon
                  v-else
                  v-on="on">
                  {{ menu.icon }}
                </v-icon>
              </template>
              <span>{{ menu.label }}</span>
            </v-tooltip>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-card>
    <v-card>
      <perfect-scrollbar :options="options">
        <v-card-text class="pt-0 px-5">
          <div
            id="area-scroll"
            v-resize="onResize"
            :style="setHeightScroll">
            <slot />
          </div>
        </v-card-text>
      </perfect-scrollbar>
    </v-card>
  </v-dialog>
</template>

<script>
  import SidebarContent from '@/components/ui/SidebarContent'
  import resizing from '@/mixins/resizing'

  export default {
    components: { SidebarContent },
    mixins: [resizing],
    props: {
      itemsMenu: {
        type: Array,
        required: true
      },
      open: {
        type: Boolean
      },
      width: {
        type: [Number, String],
        default: 450
      },
      routeToClose: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        showSidebar: false,
        right: true,
        rightDrawer: false,
        openMenu: true,
        menuActived: null
      }
    },
    computed: {
      isMobile () {
        return this.$vuetify.breakpoint.xsOnly
      },
      setHeightScroll () {
        let heightCalc = this.windowSize.y - 100
        return `height: ${heightCalc}px; overflow-y: auto;`
      }
    },
    mounted () {
      this.$emit('menuActived', this.$route.name)
    },
    methods: {
      hideModal () {
        this.$emit('close')
      },
      close () {
        let routeToClose = this.routeToClose
        this.$router.push(routeToClose)

        let path = this.$route.path.split('/').slice(-1)
        if (path.length === 1) {
          this.$emit('close')
        }
      },
      changeMenu () {
        this.$emit('change')
      }
    }
  }
</script>

<style lang="sass">
  @import '../../assets/style/placeholder'

  $width-contextual-menu: 50px //value default of miniVariantWidth prop
  $width-contextual-body: 530px //value default of width prop
  $spacer: 30px //value default of width prop

  .v-dialog.sideModal
    max-height: initial !important
    height: calc(100vh - 40px)
    position: absolute
    right: 20px
    border-radius: 20px
    .v-card
      height: 100%
      .v-card__title
        color: #ffffff
      .ps
        height: calc(100%)
        margin: 0
        color: #000
        .v-card__text
          height: calc(100% - 127px)
    #ContextualMenu
      width: 80px

  .contextual-area
    .back-link
      position: absolute
      right: 16px
      top: 15px
      text-decoration: none
      color: $primary
      z-index: 2

      .icon
        color: $accent

      &:hover, &:focus, &:active
        color: $primary

        .icon
          color: $primary

    .container .v-table th.column
      padding: 8px

    // .empty-state,
    .loading
      margin-left: -25px
      margin-top: 25px

    .forced-flex
      flex-basis: 50% !important
      max-width: 50% !important

    .full-flex
      flex-basis: 100% !important
      max-width: 100% !important

    .show-discount
      padding-left: 0 !important
      padding-right: 0 !important

    .v-table__overflow
      // overflow: hidden !important

    .v-table td
      color: $textColor

    .v-table-list-opened
      border-bottom: none !important

    .title
      height: 48px
      font-weight: normal
      border-bottom: 1px solid lighten($mediumGray, 30%)
      width: 100%
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      padding: 6px 12px
      color: $darkGray
      display: flex
      align-items: center

      .close-btn
        color: $darkGray
        &::before
          background: rgba(0, 0, 0, 0)
          transition: background .3s

        &:hover::before
          background: #eee
          opacity: 1

    .contextual-menu
      z-index: 7 //because mobile

      &-list
        justify-content: flex-start

        .v-list-item
          .svg-inline--fa
            color: #fff
            font-size: 20px

      &.v-navigation-drawer--clipped:not(.v-navigation-drawer--temporary):not(.v-navigation-drawer--is-mobile)
        z-index: 7

      &.v-navigation-drawer--is-mobile:not(.v-navigation-drawer--close),
      &.v-navigation-drawer--temporary:not(.v-navigation-drawer--close)
        box-shadow: none

      .menu-list a
        display: flex
        min-height: 50px
        justify-content: center
        width: 100%

        &.v-list-item--active
          background: $light

          .svg-inline--fa
            color: $accent

    // .has-contextual-menu
    //   width: calc(100% - #{$width-contextual-menu}) !important
    //   max-width: 100%

    .contextual-body
      padding-right: $width-contextual-menu
      z-index: 6 !important
      border-left: 1px solid lighten($mediumGray, 30%)
      // overflow: hidden
      width: 100% !important
      transform: translateX(100%) !important
      overflow: initial !important

      &.v-navigation-drawer--open
        transform: translateX(0) !important

  .has-contextual-area
    .v-btn--bottom.v-btn--floating.v-btn--fixed.v-btn--right
      // transform: translateX(-$width-contextual-menu)
      // z-index: 9
      z-index: 5

    main.content,
    .config-wrapper
      padding-right: $width-contextual-menu !important

    .config-wrapper
      padding-right: $width-contextual-menu !important
      transition: all .3s

    main.content .v-content__wrap
      padding-right: 0

    .v-btn--bottom.v-btn--floating.v-btn--fixed.v-btn--right
      transform: translateX(-$width-contextual-body)
      // z-index: 9

  #area-scroll
    @extend %scroll
    width: 100%

    > .container
      position: relative
      min-height: 100%

  @media screen and (min-width: $max-mobile)
    .contextual-area
      .contextual-body
        width: $width-contextual-body !important

    .has-contextual-area
      main.v-content
        padding-right: $width-contextual-body !important

      .config-wrapper
        padding-right: $width-contextual-body !important

</style>
