<template>
  <contextual-area
    class="user-show"
    :open="openContextualArea"
    :items-menu="menuLinks"
    :route-to-close="{name: 'Users', params: {group: 'administrator'}}"
    @change="menuClicked">
    <router-view :key="$route.fullPath" />
  </contextual-area>
</template>

<script>
  import ContextualArea from '@/components/ui/ContextualArea'
  import { link } from '@/utils/icons'

  export default {
    components: { ContextualArea },
    data () {
      return {
        openContextualArea: false,
        menuLinks: [
          { label: 'Informações Gerais', icon: link.info, to: { name: 'UserGeneral' }, active: false }
        ]
      }
    },
    mounted () {
      this.openContextualArea = true
    },
    methods: {
      menuClicked (routeName) {
        this.$router.push({ name: routeName })
      }
    }
  }
</script>
