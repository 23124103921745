<template>
  <div>
    <loading
      v-if="loading > 0"
      class="absolute-center" />
    <template v-else>
      <v-container grid-list-lg>
        <v-layout
          row
          wrap>
          <v-flex class="my-0 py-0">
            <h2 class="subtitle-1 textColor--text">
              <v-icon
                size="22px"
                class="icon-align-text mr-1">
                {{ $root.icons.layout.label }}
              </v-icon>
              Dados pessoais
            </h2>
          </v-flex>
          <form-user
            v-if="!loading"
            :items="account"
            :display-mode="displayMode"
            :case-negotiate="caseNegotiate"
            :case-create="caseCreate"
            :has-investigator="false"
            :actions-edit="$can('Users')"
            hide-submit
            email-actions
            class="px-0 negative-mt"
            @displayMode="changeDisplayMode"
            @save="saveEdit" />

          <!-- <v-flex
            md12
            xs12>
            <v-divider class="mb-3" />
          </v-flex>

          <v-flex class="mb-0 pb-0">
            <h2 class="subtitle-1 textColor--text">
              <v-icon
                size="22px"
                class="icon-align-text mr-1">
                {{ $root.icons.layout.label }}
              </v-icon>
              Grupos
            </h2>
          </v-flex>
          <form-select-groups :account="account" class="px-0 negative-mt" /> -->
        </v-layout>
      </v-container>
    </template>
  </div>
</template>

<script>
  import FormUser from '@/components/ui/forms/FormUser'
  import Loading from '@/components/ui/Loading'
  import { mapGetters } from 'vuex'
  // import FormSelectGroups from '@/components/ui/forms/FormSelectGroups'

  export default {
    components: {
      FormUser,
      Loading
      // FormSelectGroups
    },
    data () {
      return {
        loading: 0,
        displayMode: true,
        account: {
          groups: []
        },
        caseCreate: false,
        caseNegotiate: false
      }
    },
    computed: {
      ...mapGetters({
        companyId: 'currentCompany/id'
      }),
    },
    mounted () {
      this.loading = 1
      if(this.$route.params.userId){
        this.$store.dispatch('user/get', this.$route.params.userId).then(userSnapshot => {
          this.account = userSnapshot.data()
          const companiesAbilities = this.account.companiesAbilities || {}
          const companyAbility = companiesAbilities[this.companyId] || {}
          this.caseCreate = companyAbility.caseCreate
          this.caseNegotiate = companyAbility.caseNegotiate
        })
      } else {
        this.account = {
          groups: []
        }
      }
      this.loading = 0
    },
    methods: {
      changeDisplayMode (status) {
        this.displayMode = status
      },
      saveEdit (data) {
        this.updateAccount(data)
      },
      updateAccount (account) {
        this.loading = 1
        this.$root.$emit('snackbar', {show: false})
        const data = Object.assign(account, { userId: this.$route.params.userId })
        this.$store.dispatch('user/updateAccount', data)
          .then(this._success)
          .catch(this._failure)
      },
      _success () {
        this.displayMode = true
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Conta atualizada'})
        this.loading = 0
      },
      _failure () {
        console.error(...arguments)
        this.$nextTick(() => {
          this.loading = 0
          this.$root.$emit('snackbar', {show: true, color: 'error', text: 'Houve um problema'})
        })
      }
    }
  }
</script>


<style lang="sass">
  .negative-mt
    margin-top: -35px
</style>
